import React, { Component } from 'react';

import Cube3x3 from '../cube/Cube3x3';

class Home extends Component {
  // var cube;

  constructor(props) {
    super(props);

    this.state = {
      title: 'Sign Up',
      description: 'Enter a username and password here.'
    };
  }

  render() {
    return (
      <div>
        <Cube3x3></Cube3x3>
      </div>
    );
  }
}

export default Home;
