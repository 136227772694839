import React, { Component } from 'react';

import Cube from 'cubejs';
import { InitErnoCube } from './ERNO';
import * as THREE from 'three';
import { initCubeContainer } from './main';

// eslint-disable-next-line no-unused-vars
import styles from '../../styles/cube.css';

class Cube3x3 extends Component {
  // var cube;

  constructor(props) {
    super(props);

    this.state = {
      title: 'Sign Up',
      description: 'Enter a username and password here.'
    };

    this.cube = new Cube();
    this.cube.randomize();

    console.log(this.cube.asString().toLowerCase());
    this.cube.move('U');
    console.log(this.cube.toJSON());

    import('@tweenjs/tween.js').then(TWEEN => {
      // const TWEEN = new tweenMod.Tween();
      initCubeContainer(
        'cube-container',
        InitErnoCube(THREE, TWEEN),
        THREE,
        TWEEN
      );
    });
  }

  render() {
    return (
      <div>
        <h3>Solve the Cube</h3>
        <div style={{ height: 500 }} id="cube-container"></div>;
      </div>
    );
  }
}

export default Cube3x3;
