export const initCubeContainer = async (containerId, ERNO, THREE, TWEEN) => {
  const useLockedControls = true;
  const controls = useLockedControls ? ERNO.Locked : ERNO.Freeform;

  const ua = navigator.userAgent;
  const isIe = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident/') > -1;

  const { InitErnoCube } = await import('./ERNO');
  InitErnoCube(THREE, TWEEN).then(erno => {
    console.log('ERNO; ', erno);

    window.cube = new erno.Cube({
      hideInvisibleFaces: true,
      controls: controls,
      renderer: isIe ? ERNO.renderers.IeCSS3D : null
    });

    const container = document.getElementById(containerId);
    container.appendChild(window.cube.domElement);

    window.cube.shuffle(5);
    window.cube.twist('UDLF');

    if (controls === ERNO.Locked) {
      const fixedOrientation = new THREE.Euler(
        Math.PI * 0.1,
        Math.PI * -0.25,
        0
      );
      window.cube.object3D.lookAt(window.cube.camera.position);
      window.cube.rotation.x += fixedOrientation.x;
      window.cube.rotation.y += fixedOrientation.y;
      window.cube.rotation.z += fixedOrientation.z;
    }
  });
};
