import './App.css';

import { BrowserRouter, Route } from 'react-router-dom';
import React, { Component } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './Header';
import Home from '../home/Home';
import StreamCreate from '../streams/StreamCreate';
import StreamDelete from '../streams/StreamDelete';
import StreamEdit from '../streams/StreamEdit';
import StreamShow from '../streams/StreamShow';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../theme';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <div className="ui container">
            <BrowserRouter>
              <div>
                <Header />
                <Route path="/" exact component={Home} />
                <Route path="/streams/new" exact component={StreamCreate} />
                <Route path="/streams/edit" exact component={StreamEdit} />
                <Route path="/streams/delete" exact component={StreamDelete} />
                <Route path="/streams/show" exact component={StreamShow} />
              </div>
            </BrowserRouter>
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
